import { createGlobalStyle } from 'styled-components'
import { Colors, Fonts } from './styles'

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  
  }
  .container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
}

.container-inner {
  border: 1px solid #ddd;
}

.header {
    flex: 0 0 auto;
    padding: 10px;
    background-color: #f2f2f2;
    border-bottom: 1px solid #ccc;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.search-bar {
    padding: 5px;
    font-size: 16px;
    width: 200px;
}

.filters {
    display: flex;
    gap: 10px;
}

.filter-btn {
    padding: 5px 10px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid #aaa;
}

.filter-active {
  background: #cdcdcd;
}
.content {
    flex: 1 1 auto;
    overflow: hidden;
}

.table-container {
  height: 100% !important;
  overflow-y: auto; /* Allow scrolling within the table container */
  position: relative;
}

.chart-container {
  display: none;
  width: 100%;
  position: absolute;
  top: 30px;
  left: 0;
}

.chart-container.active {
  display: flex;
}

.content::-webkit-scrollbar {
  display: block;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th {
    background-color: #f2f2f2;
}

  body, html {
    margin: 0;
    width: 100%;
    height: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    color: #343434;
    overscroll-behavior-y: none;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  a {
    color: ${Colors.Black[900]};
    text-decoration: none;

    &:visited {
      color: ${Colors.Black[900]};
    }
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    line-height: 150%;
    font-weight: 600;
    font-family: ${Fonts.Helvetica};
  }

  h1 {
    font-size: 32px;
  }

  h2 {
    font-size: 24px;
  }
  
  h3 {
    font-size: 14px;
  }

  h4 {
    font-size: 12px;
  }

  p {
    margin: 0;
  }


.pillow {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 20px;
  border-radius: 5px;
  font-size: 12px;
  color: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}

.pillow-small {
  width: 24px !important;
  height: 16px !important;
}

.red {
  background-color: #e23e32e0;
}

.green {
  background-color: #4CAF50;
}

.orange {
  background-color: #f8e351;
}

.arrow {
  display: inline-block;
  vertical-align: middle;
  font-size: 10px;
  margin-left: 3px;
}

.table-item {
  height: 20px;
}

td {
  vertical-align: top !important;
}

.cell-content {
  position: relative;
}

.signals-list {
  text-align: left;
  flex-grow: 1;
}

  .signal-item {
  width: 100%;
  padding: 20px;
  border: 1px solid #ddd;
}

`

